import React , {useEffect, useRef, useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';
import SEO from '../../components/mini/seo';
import AXIOS from '../../../axios-config';
import Layout from '../../components/mini/layout';
import ReCAPTCHA from 'react-google-recaptcha';

//components
import Spinner from '../../components/spinner';
import PossibilitiesItem from '../../components/possibilitesitem';
import settings from '../../../settings';

//images
const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const herobackground = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-group-events-top-banner.jpg`;
const interactive = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/interactive-mini-instruction-popup-01.jpg`;
const expand = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-expand-smiling-driver-popup-07.jpg`;
const individual = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-individual-instruction-popup-02.jpg`;
const fun = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-drifting-fun-popup-08.jpg`;
const teamwork = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-teamwork-popup-03.jpg`;
const discover = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-discover-latest-models-popup-09.jpg`;
const confidence = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-confidence-builder-popup-05.jpg`;
const social = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-hot-lap-popup-11.jpg`;
const unique = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/mini-unique-start-popup-10.jpg`;
const delicious = `${settings.IMAGES_BASE_URL}/images/mini/groupevents/bmw-driving-school-lunch-popup.jpg`;

const data = [
  {
    id: 1,
    possibility: 'Interactive',
    image: `${interactive}`,
    imageAlt: 'BMW M5 Interactive Popup',
    title: 'An INTERACTIVE Experience',
    description:
      'When on the track, your team will forge new connections while sharing the experience of driving at the limit. It’s so engaging that the only time you may see a mobile device out is to film a hot lap.'
  },
  {
    id: 2,
    possibility: 'Expand',
    image: `${expand}`,
    imageAlt: 'BMW Driving School Class Expand Popup',
    title: 'EXPAND Your Horizons',
    description:
      'Bringing your team here will allow them to try something they likely never have before: driving a MINI on a closed course. They will build a new sense of teamwork, make new connections and have the time of their lives.'
  },
  {
    id: 3,
    possibility: 'Individual',
    image: `${individual}`,
    imageAlt: 'BMW Driving School Instruction Popup',
    title: 'INDIVIDUAL Instruction',
    description:
      'Each driving exercise is performed under the watchful eye of a MINI professional driving instructor. They will provide instant feedback and positive reinforcement while encouraging each driver to have a little fun. Maybe more than a little.'
  },
  {
    id: 4,
    possibility: 'Fun',
    image: `${fun}`,
    imageAlt: 'BMW M2 Drift Fun Popup',
    title: 'Focusing FUN',
    description:
      'It’ll be all smiles as your team drives the latest MINI models on our track. Patience, focus and the foresight to look as far ahead as you can are skills taught from actual experience behind the wheel.'
  },
  {
    id: 5,
    possibility: 'Teamwork',
    image: `${teamwork}`,
    imageAlt: 'BMW Teamwork Popup',
    title: 'TEAMWORK Time',
    description:
      'Professional instructors take the time to explain each driving concept and then demonstrate. While your team is driving, our instructors maintain open radio communication to give pointers throughout each exercise.'
  },
  {
    id: 6,
    possibility: 'Discover',
    image: `${discover}`,
    imageAlt: 'BMW X3 Discover Popup',
    title: 'DISCOVER and Drive',
    description:
      'No matter what type of exercises you choose for your program, you’ll find the latest John Cooper Works MINI 2 door Hard Tops on the tarmac waiting for you and your team to take them for a spin.'
  },
  {
    id: 7,
    possibility: 'Confidence',
    image: `${confidence}`,
    imageAlt: 'BMW M5 Drift Confidence Popup',
    title: 'CONFIDENCE Builder',
    description:
      'Learning how to handle a John Cooper Works MINI on a track takes practice (that’s where we come in), and few get it right on the first try. We instruct and encourage your team to push their own limits, as well as a MINI’s, to learn about themselves.'
  },
  {
    id: 8,
    possibility: 'Unique',
    image: `${unique}`,
    imageAlt: 'BMW M4 Coupe Unique Popup',
    title: 'A UNIQUE Event',
    description:
      'Wet skid pad. Timed laps. Rat Race. There are so many different ways to configure your day here that you may need to come back again. Make that definitely.'
  },
  {
    id: 9,
    possibility: 'Delicious',
    image: `${delicious}`,
    imageAlt: 'BMW Driving School Lunch Popup',
    title: 'DELICIOUS Delights',
    description:
      'Enjoy a professionally catered lunch in our upstairs lounge during your visit. Sit outside and take in a breathtaking view of the track and surrounding landscape, then head downstairs to our coffee bar for a quick boost. Not that you’ll need it.'
  },
  {
    id: 10,
    possibility: 'Social',
    image: `${social}`,
    imageAlt: 'Mini Car',
    title: 'SOCIAL Experience',
    description: 'This is a once-in-a-lifetime event, and you’ll find plenty of moments throughout your day to share with friends and colleagues. Use #MINIDrivingExperienceUSA and post on social media to show just how much fun track time can be.'
  }
];

const GroupEventsPage = () => {
  const [show, setShow] = useState(false);
  const [possibilities, setPossibilities] = useState([]);
  const [activePossibility, setActivePossibility] = useState('');
  const [modalImage, setModalImage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const selectedIndex = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    setPossibilities(data)
  },[activePossibility,possibilities])

  const handleModalOpen = (selectedIndex) => {
    setShow(true);
    setActivePossibility(selectedIndex);
    setModalImage(possibilities[selectedIndex].image);
    setModalDescription(possibilities[selectedIndex].description)
    setModalTitle(possibilities[selectedIndex].title)
  };

  const handleModalClose = () => {
    setShow(false);
    setActivePossibility('');
  };

  const handleNextPossibility = () => {
    let arr = possibilities.length;
    let selectedIndex = activePossibility + 1;
    selectedIndex %= arr;

    setShow(true);
    setActivePossibility(selectedIndex);
    setModalImage(possibilities[selectedIndex].image);
    setModalDescription(possibilities[selectedIndex].description)
    setModalTitle(possibilities[selectedIndex].title)
  };

  const handlePreviousPossiblity = () => {
    let arr = possibilities.length;
    let selectedIndex = activePossibility;
    if (selectedIndex === 0) {
      selectedIndex = arr - 1;
    } else {
      selectedIndex = selectedIndex - 1;
    }
    setShow(true)
    setActivePossibility(selectedIndex)
    setModalImage(possibilities[selectedIndex].image)
    setModalTitle(possibilities[selectedIndex].title)
    setModalDescription(possibilities[selectedIndex].description)
  };

  const scrollToRef = () => {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };

  const initialValues = {
    firstname: '',
    lastname: '',
    organization: '',
    zipcode: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    attendees: '7-18',
    notes: '',
    event_type: '',
    event_date: '',
    job_title: '',
    company_website: '',
    selectedLocation: []
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    organization: Yup.string()
      .required('Please enter your organization')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    address: Yup.string()
      .required('Please enter your address')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    city: Yup.string()
      .required('Please enter your city')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    state: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    zipcode: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    event_type: Yup.string()
      .required('Please enter your event type')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    event_date: Yup.date().required('Please choose your event date'),
    job_title: Yup.string()
      .required('Please enter your job title')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    company_website: Yup.string()
      .required('Please enter your company website')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    email: Yup.string()
      .email()
      .required('Please enter your email address')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Your email address is incorrect'),
    phone: Yup.string()
      .required('Please enter your phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid phone number'
      ),
    selectedLocation: Yup.array().required("Please select at least one of the location"),
  });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }


  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return;
    }

    AXIOS.post('/api/mini-groupevent', values,{headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        window.grecaptcha.reset();
        cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
      });
  };

  
    return (
      <Layout>
        <SEO title="THE MINI DRIVING EXPERIENCE | Group Events" />
        <div className="mini__hero">
          <LazyLoadImage effect="blur" src={herobackground} alt="MINI Group Events" />
          <button className="arrow" onClick={scrollToRef} onKeyDown={scrollToRef}>
            <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
          </button>
        </div>

        <section className="teambuilding">
          <div className="container">
            <div className="teambuilding__header">
              <h2 className="linethrough-header">GROUP EVENTS AND TEAM BUILDING.</h2>
              <p>
                This isn’t your typical business meeting. You and your team will spend the day at the MINI Driving
                Experience, where you’ll be provided rooms to conduct meetings, world-class cuisine, and of course
                plenty of track time.
              </p>
            </div>
          </div>
          <section className="teambuilding__testimonial">
            <div className="container">
              <div className="teambuilding__testimonial__content">
                <h3>
                  “Once in a lifetime experience for this midwesterner.  <br />Great cars, great instructors, and a great time!”
                </h3>
                <span> - HAPPY CLIENT</span>
              </div>
            </div>
          </section>
        </section>
        <section className="possibilities">
          <div className="container">
            <div className="possibilities__header">
              <h2 className="linethrough-header">TEN WORDS. INFINITE POSSIBILITIES.</h2>
              <p>
                Need a reason to take a business trip? We’ll give you ten. Click on each word to see how we’ll make the
                day, and your team, feel special.
              </p>
            </div>
            <div className="possibilities__content">
              <div className="row">
                {possibilities.map((possibility, selectedIndex) => {
                  return (
                    <React.Fragment key={possibility.id}>
                      <PossibilitiesItem
                        text={possibility.possibility}
                        index={selectedIndex}
                        image={possibility.image}
                        imageAlt={possibility.imageAlt}
                        title={possibility.title}
                        onModalOpen={handleModalOpen}
                        onModalClose={handleModalClose}
                        onNext={handleNextPossibility}
                        onPrev={handlePreviousPossiblity}
                        modalImage={modalImage}
                        modalTitle={modalTitle}
                        modalDescription={modalDescription}
                        description={possibility.description}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="getstarted">
          <div className="container">
            <div className="getstarted__header">
              <h2 className="linethrough-header">READY TO GET STARTED?</h2>
              <p>
                If you’re interested in a private event for your group, please fill out the information below and we
                will get back to you within 48 hours, or <span className="bold">call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a></span> for more
                information.
              </p>
            </div>
            <div className="getstarted__content">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="checkbox">
                      <h4>Select a location</h4>
                      <div className="checkbox__group">
                        <Field aria-label="thermal_ca" type="checkbox" name="selectedLocation" value="Thermal, CA" />
                        <label htmlFor="thermalCA">Thermal, CA </label>
                      </div>
                      <div className="checkbox__group">
                        <Field aria-label="spartanburg" type="checkbox" name="selectedLocation" value="Spartanburg, SC" />
                        <label htmlFor="spartanburg">Spartanburg, SC</label>
                      </div>
                      {errors.selectedLocation && touched.selectedLocation ? <span className="input-feedback">{errors.selectedLocation}</span> : <span className="input-feedback">&nbsp;</span>}
                    </div>
                    <div className="input">
                      <h4>Information</h4>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="firstname">First Name</label>
                          <Field
                            aria-label="firstname"
                            type="text"
                            name="firstname"
                            className={touched.firstname && errors.firstname ? 'has-error' : null}
                          />
                          {errors.firstname &&
                          touched.firstname ? <span className="input-feedback">{errors.firstname}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="lastname">Last Name</label>
                          <Field
                            aria-label="lastname"
                            type="text"
                            name="lastname"
                            className={touched.lastname && errors.lastname ? 'has-error' : null}
                          />
                          {errors.lastname &&
                          touched.lastname ? <span className="input-feedback">{errors.lastname}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="job_title">Your Job Title</label>
                          <Field
                            aria-label="job_title"
                            type="text"
                            name="job_title"
                            className={touched.job_title && errors.job_title ? 'has-error' : null}
                          />
                          {errors.job_title &&
                          touched.job_title ? <span className="input-feedback">{errors.job_title}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="organization">Organization</label>
                          <Field
                            aria-label="organization"
                            type="text"
                            name="organization"
                            className={touched.organization && errors.organization ? 'has-error' : null}
                          />
                          {errors.organization &&
                          touched.organization ? <span className="input-feedback">{errors.organization}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className='form__group'>
                        <div className="input__group">
                          <label htmlFor="email">Your Email</label>
                          <Field
                            aria-label="email"
                            type="email"
                            name="email"
                            className={touched.email && errors.email ? 'has-error' : null}
                          />
                          {errors.email && touched.email ? <span className="input-feedback">{errors.email}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="company_website">Company Website</label>
                          <Field
                            aria-label="company_website"
                            type="text"
                            name="company_website"
                            className={touched.company_website && errors.company_website ? 'has-error' : null}
                          />
                          {errors.company_website &&
                          touched.company_website ? <span className="input-feedback">{errors.company_website}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="input__group">
                        <label htmlFor="address">Address</label>
                        <Field
                          aria-label="address"
                          type="text"
                          name="address"
                          className={touched.address && errors.address ? 'has-error' : null}
                        />
                        {errors.address && touched.address ? <span className="input-feedback">{errors.address}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="city">City</label>
                          <Field aria-label="city" type="text" name="city" className={touched.city && errors.city ? 'has-error' : null} />
                          {errors.city && touched.city ? <span className="input-feedback">{errors.city}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group input--sm">
                          <label htmlFor="state">State</label>
                          <Field
                            aria-label="state"
                            type="text"
                            name="state"
                            className={touched.state && errors.state ? 'has-error' : null}
                          />
                          {errors.state && touched.state ? <span className="input-feedback">{errors.state}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="zipcode">Zip Code</label>
                          <Field
                            aria-label="zipcode"
                            type="text"
                            name="zipcode"
                            className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                          />
                          {errors.zipcode &&
                          touched.zipcode ? <span className="input-feedback">{errors.zipcode}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="phone">Phone</label>
                          <Field
                            aria-label="phone"
                            type="tel"
                            name="phone"
                            className={touched.phone && errors.phone ? 'has-error' : null}
                          />
                          {errors.phone && touched.phone ? <span className="input-feedback">{errors.phone}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="eventType">Event Type (training, seminar, etc.)</label>
                          <Field
                            aria-label="event_type"
                            type="text"
                            name="event_type"
                            className={touched.event_type && errors.event_type ? 'has-error' : null}
                          />

                          {errors.event_type &&
                          touched.event_type ? <span className="input-feedback">{errors.event_type}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="eventDate">Event Date</label>
                          <Field aria-label="event_date" type="date" name="event_date" />

                          {errors.event_date &&
                          touched.event_date ? <span className="input-feedback">{errors.event_date}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="input__group input--sm">
                        <label htmlFor="attendees">Number of Attendees</label>
                        <Field
                          aria-label="attendees"
                          as="select"
                          name="attendees"
                          className={touched.attendees && errors.attendees ? 'has-error' : null}
                        >
                          <option value="7-18">7-18</option>
                          <option value="19-49">19-49</option>
                          <option value="50-99">50-99</option>
                          <option value="100+">100+</option>
                        </Field>
                        <span className="input-feedback">&nbsp;</span>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="notes">Additional Notes</label>
                          <Field aria-label="notes" as="textarea" name="notes" />
                        </div>
                      </div>
                    </div>
                    <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
                    <button className="btn btn-black" type="submit" disabled={isSubmitting}>
                      {isLoading ? <Spinner /> : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </Layout>
    );
}

export default GroupEventsPage;
